body {
  margin: 0px;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  scroll-behavior: smooth;
}

.App {
  color: #555;
  overflow-anchor: none;
  padding-bottom: 100px;
}

html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.hide {
  opacity: 0 !important;
  pointer-events: none;
}

.tempHide {
  opacity: 0 !important;
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: #555;
  box-shadow: 0 5px 10px -5px rgb(52 52 52);
}

#App-count {
  font-size: 2.5em;
  transition: opacity 0.5s;
  opacity: 1;
}

.App-link {
  color: #61dafb !important;
}

#App-rows {
  margin: 40px;
  height: 700px;
  padding: 0px;
  transition: opacity 0.3s;
  opacity: 1;
  margin: auto;
  margin-bottom: 100px;
  text-align: center;
  margin-top: 30px;
}

.App-row {
  padding: 0px;
  display: inline-block;
  position: absolute;
  right: 0px;
}

.App-row-div {
  height: 700px;
  width: 190px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  -webkit-transition: width 0.3s ease-in-out, padding-left 0.3s ease-in-out,
    padding-right 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out, padding-left 0.3s ease-in-out,
    padding-right 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out, padding-left 0.3s ease-in-out,
    padding-right 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, padding-left 0.3s ease-in-out,
    padding-right 0.3s ease-in-out;
}

.minimize {
  width: 0px !important;
}


ol {
  padding-inline-start: 30px;
}

/*LOADER*/
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #555;
  width: 80px;
  height: 80px;
  margin-top: -70px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite, 1s ease-out 0s 1 slideInFromTopLoader;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

@keyframes slideInFromTop {
  0% {
    margin-top: -20px;
  }

  100% {
    margin-top: 0px;
  }
}

@keyframes slideInFromTopLoader {
  0% {
    margin-top: -90px;
  }

  100% {
    margin-top: -70px;
  }
}

.loader-text {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  animation: 1s ease-out 0s 1 slideInFromTop;
  z-index: 2;
  position: absolute;
}

.scooch {
  margin-top: 10px !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.elem {
  background-color: #F2F4F5;
  margin: 3px;
  animation: slidein 0.5s forwards;
  display: block;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes slidein {
  from {
    height: 0px;
    transform: scale(0);
    opacity: 0;
  }

  to {
    height: 30px;
    /*This will change for each element */
    transform: scale(1);
    opacity: 1;
  }
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

#expand-and-collapse {
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 40px;
  display: block;
  margin-top: -60px;
  display: flex;
  transition: transform 0.25s;
}

#expand-and-collapse-circle {
  border-radius: 50%;
  position: absolute;
  margin: auto;
  height: 110px;
  width: 110px;
  background-color: transparent !important;
}

/*BUTTON*/
.button {
  align-items: center;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #555;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  /*transition: all 250ms;*/
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.small-button {
  align-items: center;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #555;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  padding: 7px;
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  margin: 5px;

}

.tempRotate {
  transform: rotate(90deg) translateY(-1px);
  box-shadow: rgba(0.1, 0.1, 0.1, 0.1) 0 4px 12px;
}

.rotate {
  transform: rotate(90deg) translateY(-1px);
  box-shadow: rgba(0.1, 0.1, 0.1, 0.1) 0 4px 12px;
}

/* MODAL*/
.modal-button {
  left: 20px;
  margin: 20px;
}

.pause-button {
  float: right;
  margin: 20px;
  right: 20px;
  position: absolute;
}

.modal {
  height: 417px;
  font-size: 12px;
  z-index: 3;
  color: #555;
}

.modal>.header {
  width: 100%;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}

.modal>.actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.modal>.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }

  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  border-radius: .25rem;
}

[role='tooltip'].popup-content {
  width: 100px;
}
[role='dialog'].popup-content {
  width: 350px !important;
}

.terms {
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #555;
}

.term {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  background-color: #F2F4F5;
  padding-left: 4px;
  border-radius: .25rem;
}

.term-text {
  margin-right: 5px;
  height: 20px;
  width: 200px;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #555;
  border-width: 0px;
  letter-spacing: 1px;
}

.remove-term {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 2px;
  top: 2px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.color-input {
  width: 23px;
  height: 23px;
}

.popular-terms {
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #555;
}

h5 {
  margin-block-end: 5px;
}

.add-term {
  cursor: pointer;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 2px;
  top: 2px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.rotate45 {
  transform: rotate(45deg);
}

#explanatory-content {
  margin: 40px;
}

.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069 !important;
  text-decoration: underline;
  cursor: pointer;
}

/* Popular.js */
table {
  font-family: arial, sans-serif;
  width: 100%;
  transition: opacity 0.5s;
}

th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

td {
  text-align: left;
  padding: 8px;
  background-color: transparent !important;
}

body.light-mode tr:nth-child(even) {
  background-color: #dddddd !important;
}
body.dark-mode tr:nth-child(even) {
  background-color: #333333 !important;
}


#minCharInput {
  margin-right: 5px;
  width: 45px;
  padding: 5px;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: #555;
  background-color: #F2F4F5;
  border-width: 0px;
  letter-spacing: 1px;
  border-radius: .25rem;
}

/* HOME */
html {
  height: 100%;
}

body {
  min-height: 100%;
}

.project {
  background-color: #F2F4F5;
  max-width: 500px;
  margin-top: 20px;
  padding: 5px;
  border-radius: .25rem;
  transition: transform .15s ease-in-out;
  cursor: pointer;
  display: block;
}

.project:hover {
  transform: scale(1.05);
}

footer {
  background-color: #F2F4F5;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0px -5px 10px -5px rgb(52 52 52);
  text-align: center;
  color: #555;
  width:100%;
}

.light {
  color: rgb(126, 126, 126);
}

.footer-button {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  background-color: rgb(196, 196, 196);
  padding: 3px;
  width: fit-content;
  border-radius: .25rem;
}

@media screen and (max-width: 475px) {
  body, html {
    overflow-x: hidden !important;
  }
}

@keyframes grow {
  0% {
    width: 0px;
  }

  100% {
    width: 475px;
  }
}

#background-graphic {
  animation: 1s ease-out 0s 1 grow;
  z-index: 0;
  position: absolute;
  width: 475px;
  display: block;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* SEARCH */

.search-container {
  max-width: 265px;
  margin-bottom: 10px;
}

.search-container input[type=text] {
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
}

.search-container button {
  float: right;
  padding: 6px 10px;
  margin-top: 8px;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.search-container button:hover {
  background: #ccc;
}

label {
  font-size: 18px;
  display: block;
}

#checkboxes {
  display: inline-block;
}

* {
  overflow-anchor: none !important;
  scroll-snap-stop: normal !important;
  /* overscroll-behavior: unset !important; */
  /* scroll-behavior: unset !important; */
}

@media only screen and (max-width: 600px) {
  #down-on-small{
    margin:auto !important;
    float:none !important;
    display:block;
  }
  #down-on-small h2{
    margin-top: 150px !important;

    margin-left:auto !important;
    margin-right:auto !important;
    text-align: center;
    float:none;
  }
  #reset-countdown{
    width:100%;
    margin-top:-150px !important;
    text-align:center;
  }

  h1{
    margin-block-start: 0.6em !important;
  }

  #term-character-option{
    margin-left:auto;
    margin-right:auto;
    margin-bottom: -120px;
    position:relative !important;
  }
}

body.light-mode :not( .secondary):not(.popup-overlay), body.light-mode .primary, body.light-mode {
  background-color: #fff;
  color: #333;
}
body.dark-mode :not( .secondary):not(.popup-overlay), body.dark-mode .primary, body.dark-mode {
  background-color: #1a1919;
  color: #999;
}

body.light-mode .secondary * :not( .primary), body.light-mode .secondary {
  background-color: #F2F4F5;
  color: #333;
}
body.dark-mode .secondary * :not( .primary), body.dark-mode .secondary {
  background-color: rgb(41, 41, 41);
  color: #999;
}

g, defs, clipPath, rect, path, svg, #root > span > button > div, #root > span > button > div > div{
  background-color: transparent !important;
}

mark{
  background-color: yellow !important;
  color: black !important;
}

#usersPerSecond{
  transition: opacity 0.3s;
}